<template>
  <v-dialog
    v-if="imageSource"
    v-model="imageSource"
    @click:outside="close"
    max-width="1000"
  >
    <v-img
      :src="imageSource.src"
    >
      <v-container fluid>
        <v-row align="start">
          <v-col class="py-0">
            <v-btn
              color="error"
              @click="close"
              fixed
              large
            >
              {{ $t('close') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-img>
  </v-dialog>
</template>

<script>
export default {
	name: "ImageViewDialog",
	props: {
		imageSource: {
      type: Object,
      default: () => {}
    }
	},
	methods: {
		close() {
			this.$emit('closed')
		}
	}
}
</script>

<style scoped>

</style>