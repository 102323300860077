<template>
  <div>
    <v-row>
      <v-col
        cols="12"
        class="caption"
      >
        {{ $t('images') }}
      </v-col>
      <v-col cols="12">
        <v-carousel
          show-arrows
          v-if="getImages && getImages.length"
        >
          <v-carousel-item
            v-for="(image, index) in getImages"
            :key="'image-'+index"
            class="grey lighten-2 fill-height"
          >
            <v-img
              height="500"
              :src="image.src"
              contain
            >
              <v-chip
                label
                class="ma-2"
              >
                {{ image.title }}
              </v-chip>
            </v-img>
          </v-carousel-item>
        </v-carousel>
        <v-row
          v-else
          class="caption grey lighten-2"
          justify="center"
        >
          <v-col
            cols="12"
            class="text-center"
          >
            {{ $t('pleaseAddImagesToBeAbleToSeeImagePreview') }}
          </v-col>
        </v-row>
      </v-col>
      <v-col
        cols="12"
        class="caption"
      >
        {{ $t('youHaveFilesLengthImagesForThisItem', {filesLength: files.length}) }}
      </v-col>
      <v-col cols="12">
        <v-list>
          <v-divider />
          <template v-for="(image, index) in getImages">
            <v-list-item
              :key="'image-list-'+index"
              @click="viewImage = image"
            >
              <v-list-item-avatar>
                <v-img :src="image.src" />
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>
                  <span>
                    <v-chip
                      label
                      color="primary"
                      v-if="image.isNew"
                      small
                    >
                      {{ $t('new!') }}
                    </v-chip>
                    <v-chip
                      label
                      color="error"
                      outlined
                      v-if="image.willBeRemoved"
                      small
                    >
                      {{ $t('markForRemoval') }}
                    </v-chip>
                    {{ image.title }} <span class="caption pl-2">{{ image.size }}</span>
                  </span>
                </v-list-item-title>
              </v-list-item-content>
              <v-list-item-action @click.stop>
                <v-btn
                  color="primary"
                  small
                  v-if="image.willBeRemoved"
                  @click="cancelRemove(image)"
                >
                  {{ $t('cancelRemoval') }}
                </v-btn>
                <v-btn
                  color="error"
                  small
                  v-else
                  @click="removeFile(image, index)"
                >
                  {{ $t('remove') }}
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            <v-divider :key="'d-'+index" />
          </template>
        </v-list>
      </v-col>
      <v-col cols="12">
        <v-row dense>
          <v-col cols="12">
            <v-form v-model="fileInputIsOkay">
              <v-file-input
                filled
                dense
                show-size
                multiple
                counter
                v-model="newImages"
                chips
                :rules="rules"
                :accept="acceptedFormats"
              />
            </v-form>
            <div class="caption">
              {{ $t('filesMustBeLessThan5MB') }}
            </div>
            <div class="caption">
              {{ $t('allowedFileTypesPngJpgJpeg') }}
            </div>
          </v-col>
          <v-col cols="12">
            <v-btn
              block
              large
              color="primary"
              :disabled="!newImages || !newImages.length || !fileInputIsOkay"
              @click="addFiles"
            >
              {{ newImages && newImages.length > 1 ? this.$t('uploadNewImages') : this.$t('uploadANewImage') }} <v-icon right>
                mdi-upload
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <ImageViewDialog
      :image-source="viewImage"
      v-if="viewImage"
      @closed="viewImage = null"
    />
  </div>
</template>

<script>
// This should have an image preview, the number of images, ability to drag and drop image, as well as clicking a button to add image
import ImageViewDialog from "@/components/item/ImageViewDialog"

export default {
	name: "Images",
	components: {
		ImageViewDialog
	},
	props: {
		currentImages: {
      type: Array,
      default: () => []
    }
	},
	data() {
		return {
			files: [],
			currentlySelectedImage: 0,
			newImages: null,
			viewImage: null,
			fileInputIsOkay: false,
			rules: [
					files => !files || !files.some(file => file.size > 5000000) || this.$t('fileSizeShouldBeLessThan5MB')
			]
		}
	},
	beforeMount() {
		if(this.currentImages && this.currentImages.length) {
			this.files = this.currentImages
		}
	},
	computed: {
		acceptedFormats() {
			return 'image/png, image/jpg, image/jpeg, image/gif'
		},
		getImages() {
			if(this.files && this.files.length) {
				let fromServer = this.files.filter(image => image && image.file && image.file.sources && image.file.sources.original).map(image => {
					return {
						src: image.file.sources.original,
						title: this.getFileNameFromUrl(image.file.sources.original),
						size: this.getSizeInKb(0),
						willBeRemoved: false,
						isNew: false
					}
				})
				let fromUpload = [].concat.apply([], this.files.filter(file => file instanceof Array)).map(imageFile => {
					return {
						src: URL.createObjectURL(imageFile),
						title: imageFile.name,
						size: this.getSizeInKb(imageFile.size),
						willBeRemoved: false,
						isNew: true
					}
				})
				return fromServer.concat(fromUpload)
			} else {
				return []
			}
		}
	},
	methods: {
		getSizeInKb(size) {
			if(size === null || size === undefined || size === 0) {
				return ''
			}
			return Math.round((size/1000)) + ' kB'
		},
		getFileNameFromUrl(url) {
			return url && url.split('/').pop().split('#')[0].split('?')[0]
		},
		addFiles() {
			this.files.push(this.newImages)
			this.newImages = null
		},
		removeFile(image, index) {
			if(!image.isNew) {
				image.willBeRemoved = true
			} else {
				this.files.splice(index, 1)
			}
		},
		cancelRemove(image) {
			image.willBeRemoved = false
		},
		addDropFile(e) {
			this.files.push(...Array.from(e.dataTransfer.files))
		}
	}
}
</script>

<style scoped>
</style>
